import React from "react";
import styled, {css} from "styled-components";
import {
  TextOnlyContainer,
  Text, OuterTextContainer,
} from "../components/styledcomponents";
import ReactAudioPlayer from "react-audio-player";
import Portrait from "../img-mm40/01_About_Teichmann_mm40.jpg";
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';


const ImprintContainer = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-Y: scroll;
  background-color: #fff !important;
  color: #333 !important;
  paddingTop: 100;
  paddingBottom: 100;
`;

export class Buch extends React.Component {

  render() {
    return (
      <ImprintContainer>
        <OuterTextContainer>
          <TextOnlyContainer>
            <Text black xl underlined>Memento Mori 4.0: Die neue Generation der Bestatter*innen</Text>
            <Text black bottompadding>
            ist eine authentische Dokumentation, die tief in die Seele eines Berufsstandes eintaucht, der sich aktuell in einem existentiellen Umbruch befindet.
<br/><br/>Den moralischen und ethischen Grundwerten des Berufes verpflichtet, sucht eine junge Generation nach neuen und zeitgemäßen Symbolen und Ritualen, um Trauer und Schmerz, Trost und Empathie Ausdruck zu verleihen.
<br/><br/>Dem Fotografen Andreas Teichmann ist es über einen Zeitraum von mehr als drei Jahren gelungen, Zugang zu einer Branche zu bekommen, der bisher nur wenigen gewährt wurde.
<br/><br/>Seine Aufnahmen zeigen die Arbeitswirklichkeit junger Bestatter*innen abseits vermeintlicher Klischees und falscher Vorstellungen.
<br/><br/>Zahlreiche Begegnung liefern faszinierende Einblicke in eine Welt zwischen Tradition und Innovation, zwischen Leben und Tod.
<br/><br/>Was bewegt einen jungen Menschen, Bestatter*in zu werden? Und wie verändert sich das eigene Leben, wenn man sich immer wieder mit dem Tod beschäftigt?
<br/><br/>Anhand von 16 jungen Persönlichkeiten dokumentiert Teichmann seine fotografische Recherche, die ihn in alle Regionen des Landes geführt hat.
<br/><br/>Begleitet von einfühlsamen Gesprächen, offenbaren seine Aufnahmen unerwartete Begebenheiten und Einsichten in den Arbeitsalltag und die Gedankenwelt der Porträtierten.
<br/><br/>Das Ergebnis ist eine bislang unbekannte und faszinierende Reise durch die deutsche Bestattungskultur der Gegenwart.
<br/><br/>Das Buch zum kompletten Projekt ist im Verlag Kettler erschienen und kann hier bestellt werden:
<br/><br/><a href="https://www.verlag-kettler.de/de/buecher/memento-mori-40/">www.verlag-kettler.de/de/buecher/memento-mori-40</a>
            </Text>
          </TextOnlyContainer>
        </OuterTextContainer>
      </ImprintContainer>
    );
  }
}