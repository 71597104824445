import React from "react";
import ScrollMagic from "scrollmagic";
import { stack as Menu } from "react-burger-menu";
import { menuStyles } from "./styles";
import {
  ImageContainer,
  Image,
  ImagePreloader,
  Section,
} from "../components/styledcomponents";
import { content } from "../etc/content";
import { Imprint } from "./imprint";
import { About } from "./about";
import { Buch } from "./buch";
import { Ausstellung } from "./ausstellung";
import { MobileContentPlayer } from "./mobilecontentplayer";

// map QR-Code number to sectionId
const contentMap = {
  1: "IMG02",
  2: "IMG06",
  3: "IMG10",
  4: "IMG14",
  5: "IMG18",
  6: "IMG22",
  7: "IMG26",
  8: "IMG30",
  9: "IMG35",
  10: "IMG39",
  11: "IMG43",
  12: "IMG47",
  13: "IMG51",
  14: "IMG55",
  15: "IMG59",
  16: "IMG63",
  17: "IMG03",
  18: "IMG07",
  19: "IMG11",
  20: "IMG15",
  21: "IMG19",
  22: "IMG23",
  23: "IMG27",
  24: "IMG31",
  25: "IMG37",
  26: "IMG40",
  27: "IMG45",
  28: "IMG50",
  29: "IMG54",
  30: "IMG57",
  31: "IMG60",
  32: "IMG66",
};

function getContentId(value) {
  const sectionId = contentMap[value];
  return sectionId;
}

export class TIGIPMainView extends React.Component {
  constructor() {
    super();
    this.state = {
      currentImage: 1,
      img: null,
      showImage: false,
      currentScene: "section_dummy_",
      menuOpen: false,
    };
    this.breakpoint = this.getBreakpoint();
    this.controller = new ScrollMagic.Controller();
    this.defaultDuration = window.innerHeight;
    this.onResize = this.onResize.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.innerWidth = window.innerWidth;
  }

  isMobile(){
    return this.getBreakpoint() === "smallWidth";
  }

  getBreakpoint() {
    return window.innerWidth > 1000
      ? "maxWidth"
      : window.innerWidth > 700
      ? "mediumWidth"
      : "smallWidth";
  }

  getDurationForElement(id) {
    return document.getElementById(id).offsetHeight;
  }

  onResize() {
    if (document.fullscreenElement){
      return; // fix Safari bug: do not reload when Safari shows Video in Fullscreen mode
    }
    const newBreakpoint = this.getBreakpoint();
    if (newBreakpoint !== this.breakpoint) {
      this.breakpoint = newBreakpoint;
      this.scrollTo("#Start");
      window.location.reload();
    }
    if (window.innerWidth !== this.innerWidth) {
      this.innerWidth = window.innerWidth;
      this.scrollTo("#Start");
      window.location.reload();
    }
  }

  onToggleMenu(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  scrollTo(id) {
    this.setState({ currentPage: "home" });
    this.controller.scrollTo(id);
    this.closeMenu();
  }

  setPage(id) {
    this.setState({ currentPage: id });
    this.closeMenu();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  componentDidMount() {
    /* resize handler */
    window.addEventListener("resize", this.onResize);

    /* content */
    const setState = this.setState.bind(this);
    const duration = this.defaultDuration;

    content.forEach((item, idx) => {
      const triggerElement = "#" + item.sectionId;
      const duration = this.getDurationForElement(item.sectionId);
      const triggerHook = 0.8;
      const nextItem = content[idx + 1];
      const newState = {
        currentScene: item.sectionId,
        nextImg: nextItem?.image,
      };
      new ScrollMagic.Scene({
        triggerElement: triggerElement,
        duration: duration,
        triggerHook: triggerHook,
      })
        .on("enter leave", (e) => {
          if (e.type === "enter") {
            /* change content */
            setState(newState);
            const imageState =
              this.state.currentImage === 1
                ? {
                    img: item.image,
                    imagePosition: item?.imagePosition,
                    mobileImagePosition: item?.mobileImagePosition,
                    showImage: true,
                    currentImage: 2,
                  }
                : {
                    img2: item.image,
                    imagePosition2: item?.imagePosition,
                    mobileImagePosition2: item?.mobileImagePosition,
                    showImage2: true,
                    currentImage: 1,
                  };
            if (item.image && imageState) {
              setState(imageState);
            }
          } else {
            const newState =
              this.state.currentImage === 1
                ? { showImage2: false }
                : { showImage: false };
            setState(newState);
          }
        })
        .addTo(this.controller);
    });

    /* scroll to audio/video via QR-Code redirect */
    setTimeout(() => {
      const queryString = window.location.search;
      if (queryString.startsWith("?") && queryString.length > 1) {
        const param = queryString.substr(1);
        
        if (this.isMobile()){
          this.setState({ contentPlayerId: param });
        } else {
          this.scrollTo("#"+getContentId(param));
        }
      }

      if (window.history?.pushState) {
        window.history.pushState({ path: window.origin }, "", window.origin);
      }
    }, 500);
  }

  render() {
    const {
      currentPage,
      currentScene,
      img,
      img2,
      imagePosition,
      imagePosition2,
      mobileImagePosition,
      mobileImagePosition2,
      showImage,
      showImage2,
      nextImg,
      menuOpen,
      contentPlayerId,
    } = this.state;

    const orientation =
      window.innerWidth / window.innerHeight > 1 ? "landscape" : "portrait";

    return (
      <React.Fragment>
        {!contentPlayerId && (
          <Menu
            right
            styles={menuStyles}
            isOpen={menuOpen}
            onStateChange={this.onToggleMenu}
          >
            <div onClick={(e) => { this.scrollTo("#Start"); }}><b>Start</b></div>
            <div onClick={(e) => { this.scrollTo("#IMG02-A"); }}>Rebecca Lindner</div>
            <div onClick={(e) => { this.scrollTo("#IMG06-A"); }}>Pearl Friedrich</div>
            <div onClick={(e) => { this.scrollTo("#IMG10-A"); }}>Johannes Eichberger</div>
            <div onClick={(e) => { this.scrollTo("#IMG14-A"); }}>Emily Maichle</div>
            <div onClick={(e) => { this.scrollTo("#IMG18-A"); }}>Max Michal</div>
            <div onClick={(e) => { this.scrollTo("#IMG22-A"); }}>Jasmin Przybylsky</div>
            <div onClick={(e) => { this.scrollTo("#IMG26-A"); }}>Tim Schamborski</div>
            <div onClick={(e) => { this.scrollTo("#IMG30-A"); }}>Kay Seemann</div>
            <div onClick={(e) => { this.scrollTo("#IMG35-A"); }}>Tim Osterndorff</div>
            <div onClick={(e) => { this.scrollTo("#IMG39-A"); }}>Merrick Rohwer</div>
            <div onClick={(e) => { this.scrollTo("#IMG43-A"); }}>Hans Dieckmann</div>
            <div onClick={(e) => { this.scrollTo("#IMG47-A"); }}>Antonia Gneist</div>
            <div onClick={(e) => { this.scrollTo("#IMG51-A"); }}>Linda Heiland</div>
            <div onClick={(e) => { this.scrollTo("#IMG55-A"); }}>Esther Fuß</div>
            <div onClick={(e) => { this.scrollTo("#IMG59-A"); }}>Franziska Ritscher</div>
            <div onClick={(e) => { this.scrollTo("#IMG63-A"); }}>Zoe Duchêne</div>
            <div></div>
            <div></div>
            <div onClick={() => { this.setPage("About"); }}><b>About / Buch / Ausstellung</b></div>
            {/* <div onClick={() => { this.setPage("Buch"); }}><b>Buch</b></div>
            <div onClick={() => { this.setPage("Ausstellung"); }}><b>Ausstellung</b></div> */}
            <div onClick={() => { this.setPage("Imprint"); }}><b>Impressum / Datenschutz</b></div>
          </Menu>
        )}

        {currentPage === "Imprint" && <Imprint />}
        {currentPage === "About" && <About />}
        {currentPage === "Buch" && <Buch />}
        {currentPage === "Ausstellung" && <Ausstellung />}

        {contentPlayerId && <MobileContentPlayer mobile contentId={contentPlayerId} />}

        {!contentPlayerId && (
          <div>
            <ImageContainer>
              <Image
                src={img}
                showImage={showImage}
                imagePosition={imagePosition}
                mobileImagePosition={mobileImagePosition}
                orientation={orientation}
              />
            </ImageContainer>
            <ImageContainer>
              <Image
                src={img2}
                showImage={showImage2}
                imagePosition={imagePosition2}
                mobileImagePosition={mobileImagePosition2}
                orientation={orientation}
              />
            </ImageContainer>

            <ImagePreloader src={nextImg} width={1} height={1} />

            <Section
              id="leadIn"
              style={{ height: "40vh" }}
              show={currentScene === "leadIn"}
            />

            {content.map((item) => {
              return (
                <Section
                  key={item.sectionId}
                  id={item.sectionId}
                  style={{ height: item.height || "auto" }}
                  show={currentScene === item.sectionId}
                >
                  {item.content}
                </Section>
              );
            })}
          </div>
        )}
      </React.Fragment>
    );
  }
}
