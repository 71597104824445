import React from "react";
import "./App.css";
import "./fonts/CormorantGaramond-Regular.ttf";
import "./fonts/CormorantGaramond-Italic.ttf";
import "./fonts/CormorantGaramond-Bold.ttf";
import { TIGIPMainView } from "./components/TIGIPMainView";

function App() {
  return (
    <TIGIPMainView />
  );
}

export default App;
