import React from "react";
import styled, {css} from "styled-components";
import {
  TextOnlyContainer,
  Text, OuterTextContainer,
} from "../components/styledcomponents";
import ReactAudioPlayer from "react-audio-player";
import Portrait from "../img-mm40/01_About_Teichmann_mm40.jpg";
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';


const ImprintContainer = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-Y: scroll;
  background-color: #fff !important;
  color: #333 !important;
  paddingTop: 100;
  paddingBottom: 100;
`;

export class Ausstellung extends React.Component {

  render() {
    return (
      <ImprintContainer>
        <OuterTextContainer>
          <TextOnlyContainer>
            <Text black xl underlined>Ausstellung</Text>
            <Text black bottompadding>
<br/><br/><a href="https://www.sepulkralmuseum.de/veranstaltungen/veranstaltungen/memento-mori-4.0">www.sepulkralmuseum.de/veranstaltungen/veranstaltungen/memento-mori-4.0</a>
            </Text>
          </TextOnlyContainer>
        </OuterTextContainer>
      </ImprintContainer>
    );
  }
}