import React from "react";
import { content } from "../etc/content";
import {
  ImageContainer,
  Image,
  Section,
  Text,
} from "./styledcomponents";

// map QR-Code number to sectionId
const contentMap = {
  1: "IMG02",
  2: "IMG06",
  3: "IMG10",
  4: "IMG14",
  5: "IMG18",
  6: "IMG22",
  7: "IMG26",
  8: "IMG30",
  9: "IMG35",
  10: "IMG39",
  11: "IMG43",
  12: "IMG47",
  13: "IMG51",
  14: "IMG55",
  15: "IMG59",
  16: "IMG63",
  17: "IMG03",
  18: "IMG07",
  19: "IMG11",
  20: "IMG15",
  21: "IMG19",
  22: "IMG23",
  23: "IMG27",
  24: "IMG31",
  25: "IMG37",
  26: "IMG40",
  27: "IMG45",
  28: "IMG50",
  29: "IMG54",
  30: "IMG57",
  31: "IMG60",
  32: "IMG66",
};

function getContentBySectionId(value) {
  const sectionId = contentMap[value];
  return content.find((item) => item.sectionId === sectionId);
}

export class MobileContentPlayer extends React.PureComponent {
  render() {
    const { mobile, contentId } = this.props;
    const toRender = getContentBySectionId(contentId);
    
    return (
      <>
        {!mobile && (
          <>
          {
            contentId > 16 &&
            <ImageContainer>
              <Image src={toRender.image} showImage={true} imagePosition={toRender.imagePosition} />
            </ImageContainer>
          }
              {toRender.content}
            <div
              bottompadding
              white
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                padding: 30,
                backgroundColor: 'rgba(0,0,0,0.5)',
                color: "#fff",
              }}
            >
              <a class="awhite" href={window.location.origin}>
                Zu allen Bildern und Stories »
              </a>
            </div>
          </>
        )}
        {mobile && (
          <>
          {
            contentId > 16 &&
            <div style={{ marginBottom: -5 }}>
              <img src={toRender.image} style={{ width: "100%" }} />
            </div>
          }
            <Section
              key={toRender.sectionId}
              id={toRender.sectionId}
              style={{ height: "auto" }}
              show={true}
            >
              {toRender.content}
            </Section>
            <Text
              bottompadding
              black
              style={{
                justifyContent: "center",
                display: "flex",
                margin: 0,
                padding: 0,
                marginTop: 50,
                marginBottom: 50,
              }}
            >
              <a href={window.location.origin}>
                Zu allen Bildern und Stories »
              </a>
            </Text>
          </>
        )}
      </>
    );
  }
}
