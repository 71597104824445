import React from "react";
import ReactAudioPlayer from "react-audio-player";
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import {
  OuterTextContainer,
  TextContainer,
  Text,
} from "../components/styledcomponents";

import blank from "../img-mm40/Blank.jpeg";
import titel from "../img-mm40/01_20230705A_1333MM_Intro.jpg";
import titel_mobile from "../img-mm40/01_20230705A_1333MM_Intro_mobile.jpg";
import titel_schrift from "../img-mm40/Titel-Schriftzug.png";

import image02 from "../img-mm40/02_20211026A_01354PR_Portrait.jpg";
import image03 from "../img-mm40/03_20211028A_01756PR_Audio.jpg";
import image04 from "../img-mm40/04_20211028A_01486MM.jpg";
import image05 from "../img-mm40/05_20211028A_01613MM.jpg";
import image06 from "../img-mm40/06_20211108A_1041MM_Portrait.jpg";
import image07 from "../img-mm40/07_20211108A_1532MM_Audio.jpg";
import image08 from "../img-mm40/08_20211108A_1883MM.jpg";
import image09 from "../img-mm40/09_20211108A_2408MM.jpg";
import image10 from "../img-mm40/10_20211116A_2490MM_Portrait.jpg";
import image11 from "../img-mm40/11_20211116A_1633MM_Audio.jpg";
import image12 from "../img-mm40/12_20211116A_1359MM.jpg";
import image13 from "../img-mm40/13_20211116A_1306MM.jpg";
import image14 from "../img-mm40/14_20211208A_0939MM_Portrait.jpg";
import image15 from "../img-mm40/15_20211208A_1688MM_Audio.jpg";
import image16 from "../img-mm40/16_20211208A_2325MM.jpg";
import image17 from "../img-mm40/17_20211208A_0700MM.jpg";
import image18 from "../img-mm40/18_20220118A_1802MM_Portrait.jpg";
import image19 from "../img-mm40/19_20220118A_2552MM_Audio.jpg";
import image20 from "../img-mm40/20_20220118A_1716MM.jpg";
import image21 from "../img-mm40/21_20220118A_1412MM.jpg";
import image22 from "../img-mm40/22_20220208A_2210MM_Portrait.jpg";
import image23 from "../img-mm40/23_20220208A_1388MM_Audio.jpg";
import image24 from "../img-mm40/24_20220208A_0154MM.jpg";
import image25 from "../img-mm40/25_20220208A_2012MM.jpg";
import image26 from "../img-mm40/26_20220301A_1796MM_Portrait.jpg";
import image27 from "../img-mm40/27_20220301A_2537MM_Audio.jpg";
import image28 from "../img-mm40/28_20220301A_1658MM.jpg";
import image29 from "../img-mm40/29_20220301A_0452MM.jpg";
import image30 from "../img-mm40/30_20220316A_1120MM_Portrait.jpg";
import image31 from "../img-mm40/31_20220316A_0376MM_Audio.jpg";
import image32 from "../img-mm40/32_20220316A_2478MM.jpg";
import image33 from "../img-mm40/33_20220316A_2841MM.jpg";
import image34 from "../img-mm40/34_20220322A_1401MM.jpg";
import image35 from "../img-mm40/35_20230424A_2739MM_Portrait.jpg";
import image36 from "../img-mm40/36_20230424A_0381MM.jpg";
import image37 from "../img-mm40/37_20230424A_1425MM_Audio.jpg";
import image38 from "../img-mm40/38_20230424A_1657MM.jpg";
import image39 from "../img-mm40/39_20230504A_3108MM_Portrait.jpg";
import image40 from "../img-mm40/40_20230504A_1594MM_Audio.jpg";
import image41 from "../img-mm40/41_20230504A_1522MM.jpg";
import image42 from "../img-mm40/42_20230504A_0521MM.jpg";
import image43 from "../img-mm40/43_20230510A_2862MM_Portrait.jpg";
import image44 from "../img-mm40/44_20230510A_0507MM.jpg";
import image45 from "../img-mm40/45_20230510A_2209MM_Audio.jpg";
import image46 from "../img-mm40/46_20230510A_2492MM.jpg";
import image47 from "../img-mm40/47_20230531_A2144MM_Portrait.jpg";
import image48 from "../img-mm40/48_20230531_A0407MM.jpg";
import image49 from "../img-mm40/49_20230531_A0913MM.jpg";
import image50 from "../img-mm40/50_20230531_A1846MM_Audio.jpg";
import image51 from "../img-mm40/51_20230705A_2289MM_Portrait.jpg";
import image52 from "../img-mm40/52_20230705A_0901MM.jpg";
import image53 from "../img-mm40/53_20230705A_1333MM.jpg";
import image54 from "../img-mm40/54_20230705A_1602MM_Audio.jpg";
import image55 from "../img-mm40/55_20230726_A2401MM_Portrait.jpg";
import image56 from "../img-mm40/56_20230726_A0600MM.jpg";
import image57 from "../img-mm40/57_20230726_A3034MM_Audio.jpg";
import image58 from "../img-mm40/58_20230726_A2329MM.jpg";
import image59 from "../img-mm40/59_20230823A_1243MM_Portrait.jpg";
import image60 from "../img-mm40/60_20230823A_1907MM_Audio.jpg";
import image61 from "../img-mm40/61_20230823A_1457MM.jpg";
import image62 from "../img-mm40/62_20230823A_2052MM.jpg";
import image63 from "../img-mm40/63_20230830A_0789MM_Portrait.jpg";
import image64 from "../img-mm40/64_20230830A_1242MM.jpg";
import image65 from "../img-mm40/65_20230830A_1124MM.jpg";
import image66 from "../img-mm40/66_20230830A_0650MM_Audio.jpg";

const sectionDefaultHeight = "180vh";

export const content = [
  {
    sectionId: "Start",
    height: sectionDefaultHeight,
    image: window.innerWidth > 1200 ? titel : titel_mobile,
    content: (
      <OuterTextContainer left>
        <TextContainer transparent>
          <div style={{marginTop: 20, paddingBottom: 20}}>
          <img src={titel_schrift} width="100%" />
          </div>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG02-A",
    height: sectionDefaultHeight,
    image: image02,
    content: (
      <OuterTextContainer left>
        <TextContainer white>
          <Text tall bottompadding underlined black>
          Rebecca Lindner (*1996)
          </Text>
          <Text bottompadding black>
           Bestattungsfachkraft • bis 2023 angestellt beim Bestattungshaus Wendland • Herne • NRW
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG02",
    height: sectionDefaultHeight,
    image: image02,
    content: (
      <OuterTextContainer left>
        <TextContainer>
            <video style={{maxHeight: "100vh", width:"100%"}} controls preload="none" poster="/video/video-player-titel-02.jpg">
              <source src={`${window.location.origin}/video/01_Rebecca_Web.mp4#t=0.001`} type="video/mp4" />
              Ihr Browser unterstützt kein Video.
            </video>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG03-A",
    height: sectionDefaultHeight,
    image: image03,
    imagePosition: "left",
    mobileImagePosition: "left",
    content: (
      <OuterTextContainer right>
        <TextContainer>
          <Text bottompadding>
          Vor der Trauerfeier mit anschließender Urnenbeisetzung auf dem ev. Friedhof Wanne Süd. Rebecca und ihr Kollege dekorieren das Podest der Friedhofskapelle.
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG03",
    height: sectionDefaultHeight,
    image: image03,
    imagePosition: "left",
    mobileImagePosition: "left",
    content: (
      <OuterTextContainer right>
        <TextContainer>
          <Text tall bottompadding underlined>
          » … Mir wurde mal gesagt, ein Bestatter ist ein Eventmanager für den Tod.«
          </Text>
          <Text bottompadding>
            <ReactAudioPlayer
              src={window.location.origin + "/audio/01_Rebecca_Audiozitat_Web.m4a"}
              style={{ outline: "none" }}
              controls
              controlsList="nodownload"
              preload="none"
            />
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG04",
    height: sectionDefaultHeight,
    image: image04,
    imagePosition: "left",
    content: (
      <OuterTextContainer right>
        <TextContainer>
          <Text bottompadding>
          Büroablage für die Korrespondenz mit dem Ordnungsamt.
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG05",
    height: sectionDefaultHeight,
    image: image05,
    imagePosition: "left",
    mobileImagePosition: "right",
    content: (
      <OuterTextContainer right>
        <TextContainer>
          <Text bottompadding>
            Rebecca und ihre Kollegen packen ihre Materialien für die nächste Trauerfeier.
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG06-A",
    height: sectionDefaultHeight,
    image: image06,
    content: (
      <OuterTextContainer left>
        <TextContainer white>
          <Text tall bottompadding underlined black>
          Pearl Friedrich (*1999)
          </Text>
          <Text bottompadding black>
           Bestattungsfachkraft • angestellt bei Bestattungen Dunker • Leipzig • Sachsen
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG06",
    height: sectionDefaultHeight,
    image: image06,
    
    content: (
      <OuterTextContainer left>
        <TextContainer>
  <video style={{maxHeight: "100vh", width:"100%"}} controls preload="none" poster="/video/video-player-titel-06.jpg">
    <source src={`${window.location.origin}/video/02_Pearl_Web.mp4#t=0.001`} type="video/mp4" />
    Ihr Browser unterstützt kein Video.
  </video>
</TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG07-A",
    height: sectionDefaultHeight,
    image: image07,
    imagePosition: "left",
    content: (
      <OuterTextContainer right>
        <TextContainer>
          <Text bottompadding>
          Auf dem Leipziger Südfriedhof begrüßt Pearl Ute Fernow, die Verantwortliche der dortigen Leichenhalle.
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG07",
    height: sectionDefaultHeight,
    image: image07,
    imagePosition: "left",
    content: (
      <OuterTextContainer right>
        <TextContainer>
          <Text tall bottompadding underlined>
          » … Aber für mich ist es einfach ungewohnt, Wärme von anderen Körpern zu empfangen, weil die meisten Körper, mit denen ich zu tun habe, einfach kalt sind.«
          </Text>
          <Text bottompadding>
            <ReactAudioPlayer
              src={window.location.origin + "/audio/02_Pearl_Audiozitat_Web.m4a"}
              style={{ outline: "none" }}
              controls
              controlsList="nodownload"
              preload="none"
            />
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG08",
    height: sectionDefaultHeight,
    image: image08,
    
    content: (
      <OuterTextContainer left>
        <TextContainer white>
          <Text bottompadding black>
          Vorbereitung für die Trauerfeier in der Trauerhalle des Parkfriedhofs Connewitz. 
          Dokumentation der geleisteten Ausstattung für die Angehörigen.
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG09",
    height: sectionDefaultHeight,
    image: image09,
    imagePosition: "right",
    content: (
      <OuterTextContainer left>
        <TextContainer>
          <Text bottompadding>
          Einsargen und Ankleiden eines Verstorbenen.
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG10-A",
    height: sectionDefaultHeight,
    image: image10,
    imagePosition: "left",
    content: (
      <OuterTextContainer right>
        <TextContainer>
          <Text tall bottompadding underlined>
            Johannes Eichberger (*1990)
          </Text>
          <Text bottompadding>
            Bestattermeister • angestellt bei Mächerle Bestattungen • Wörth am Rhein • Rheinland-Pfalz
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG10",
    height: sectionDefaultHeight,
    image: image10,
    imagePosition: "left",
    content: (
      <OuterTextContainer right>
<TextContainer>
  <video style={{maxHeight: "100vh", width:"100%"}} controls preload="none" poster="/video/video-player-titel-10.jpg">
    <source src={`${window.location.origin}/video/03_Johannes_Web.mp4#t=0.001`} type="video/mp4" />
    Ihr Browser unterstützt kein Video.
  </video>
</TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG11-A",
    height: sectionDefaultHeight,
    image: image11,
    imagePosition: "right",
    content: (
      <OuterTextContainer left>
        <TextContainer>
          <Text bottompadding>
            Beim Sargausschlagen bekommt Johannes einen Anruf. Weil das Büro nicht mehr besetzt ist, 
            kümmert er sich um die Anrufer.
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG11",
    height: sectionDefaultHeight,
    image: image11,
    imagePosition: "right",
    content: (
      <OuterTextContainer left>
        <TextContainer>
          <Text tall bottompadding underlined>
            » Wir haben als Kinder zwischen den Särgen gespielt, so der Klassiker, den die Bestatter-Junioren immer erzählen.«
          </Text>
          <Text bottompadding>
            <ReactAudioPlayer
              src={window.location.origin + "/audio/03_Johannes_Audiozitat_Web.m4a"}
              style={{ outline: "none" }}
              controls
              controlsList="nodownload"
              preload="none"
            />
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG12",
    height: sectionDefaultHeight,
    image: image12,
    imagePosition: "left",
    content: (
      <OuterTextContainer right>
        <TextContainer>
          <Text bottompadding>
            Abholung einer Verstorbenen im Vinzensius-Krankenhaus in Landau. In der Kühlung muss erst der Name der Verstorbenen identifiziert werden.
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG13",
    height: sectionDefaultHeight,
    image: image13,
    imagePosition: "right",
    content: (
      <OuterTextContainer left>
        <TextContainer>
          <Text bottompadding>
            Überlandfahrt zum Vinzensius-Krankenhaus in Landau zur Abholung einer Verstorbenen. Manche Leichenwagen fahren ca. 50.000 km pro Jahr.
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG14-A",
    height: sectionDefaultHeight,
    image: image14,
    content: (
      <OuterTextContainer left>
        <TextContainer white>
          <Text tall bottompadding underlined black>
            Emily Maichle (*2000)
          </Text>
          <Text bottompadding black>
            Bestattermeisterin • arbeitet im Familienbetrieb Maichle Bestattungen • Geislingen an der Steige • Baden-Württemberg
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG14",
    height: sectionDefaultHeight,
    image: image14,
    content: (
      <OuterTextContainer left>
<TextContainer>
  <video style={{maxHeight: "100vh", width:"100%"}} controls preload="none" poster="/video/video-player-titel-14.jpg">
    <source src={`${window.location.origin}/video/04_Emily_Web.mp4#t=0.001`} type="video/mp4" />
    Ihr Browser unterstützt kein Video.
  </video>
</TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG15-A",
    height: sectionDefaultHeight,
    image: image15,
    imagePosition: "right",
    content: (
      <OuterTextContainer left>
        <TextContainer black>
          <Text bottompadding>
            Emily bringt die Schmuckurne zum Friedhof in Unterböhringen. 
            Vorbereitung zur Trauerfeier mit anschließender Urnenbeisetzung.
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG15",
    height: sectionDefaultHeight,
    image: image15,
    imagePosition: "right",
    content: (
      <OuterTextContainer left>
        <TextContainer black>
          <Text tall bottompadding underlined>
            » Aber der Preis wird zunehmend ein immer wichtigerer Aspekt, auch bei der Bestattung.«
          </Text>
          <Text bottompadding>
            <ReactAudioPlayer
              src={window.location.origin + "/audio/04_Emily_Audiozitat_Web.m4a"}
              style={{ outline: "none" }}
              controls
              controlsList="nodownload"
              preload="none"
            />
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG16",
    height: sectionDefaultHeight,
    image: image16,
    mobileImagePosition: "left",
    content: (
      <OuterTextContainer right>
        <TextContainer>
          <Text bottompadding>
            »Haus der Zeit«: Blick in die Wagenwäsche: Emily reinigt den Bestattungskraftwagen. 
            Das Institut bewohnt die Räume eines ehemaligen Autohauses.
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG17",
    height: sectionDefaultHeight,
    image: image17,
    imagePosition: "right",
    content: (
      <OuterTextContainer left>
        <TextContainer>
          <Text bottompadding>
            Ein Behälter mit dem Jahresvorrat an Weihwasser steht im Aufenthaltsraum des Pfarrers.
            Trauerhalle auf dem Friedhof in Deggingen.
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG18-A",
    height: sectionDefaultHeight,
    image: image18,
    mobileImagePosition: "right",
    content: (
      <OuterTextContainer left>
        <TextContainer white>
          <Text tall bottompadding underlined black>
            Max Michal (*1996)
          </Text>
          <Text bottompadding black>
            Bestattungsfachkraft • arbeitet im Familienbetrieb Michal Bestattungen • Schweinfurt • Bayern
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG18",
    height: sectionDefaultHeight,
    image: image18,
    mobileImagePosition: "right",
    content: (
      <OuterTextContainer left>
<TextContainer>
  <video style={{maxHeight: "100vh", width:"100%"}} controls preload="none" poster="/video/video-player-titel-18.jpg">
    <source src={`${window.location.origin}/video/05_Max_Web.mp4#t=0.001`} type="video/mp4" />
    Ihr Browser unterstützt kein Video.
  </video>
</TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG19-A",
    height: sectionDefaultHeight,
    image: image19,
    imagePosition: "left",
    content: (
      <OuterTextContainer right>
        <TextContainer black>
          <Text bottompadding>
            In der Umkleide im Firmensitz in Schweinfurt befindet sich eine Deutschlandkarte, auf der mit Nadeln markiert ist, 
            an welchen Orten die Firma bereits tätig war.
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG19",
    height: sectionDefaultHeight,
    image: image19,
    imagePosition: "left",
    content: (
      <OuterTextContainer right>
        <TextContainer black>
          <Text tall bottompadding underlined>
            » Was das Ganze noch ein bisschen schwieriger macht, dass wir in Deutschland 16 Bundesländer haben und 16 Bestattungsgesetze…«
          </Text>
          <Text bottompadding>
            <ReactAudioPlayer
              src={window.location.origin + "/audio/05_Max_Audiozitat_Web.m4a"}
              style={{ outline: "none" }}
              controls
              controlsList="nodownload"
              preload="none"
            />
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG20",
    height: sectionDefaultHeight,
    image: image20,
    mobileImagePosition: "right",
    content: (
      <OuterTextContainer left>
        <TextContainer>
          <Text bottompadding>
            Vater und Sohn haben um 09:00 Uhr ihre morgendliche Brotzeit. Sie pflegen dieses Ritual täglich, 
            um auch private Gespräche führen zu können.
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG21",
    height: sectionDefaultHeight,
    image: image21,
    imagePosition: "right",
    content: (
      <OuterTextContainer left>
        <TextContainer>
          <Text bottompadding>
            Max bespricht mit einer Kundin im Rahmen eines Bestattungsvorsorgegesprächs die Planung ihrer eigenen Trauerfeier.
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG22-A",
    height: sectionDefaultHeight,
    image: image22,
    content: (
      <OuterTextContainer left>
        <TextContainer white>
          <Text tall bottompadding underlined black>
            Jasmin Przybylsky (*1996)
          </Text>
          <Text bottompadding black>
            Bestattungsfachkraft • angestellt bei Christian-Peter Bestattungen • Berlin
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG22",
    height: sectionDefaultHeight,
    image: image22,
    content: (
      <OuterTextContainer left>
<TextContainer>
  <video style={{maxHeight: "100vh", width:"100%"}} controls preload="none" poster="/video/video-player-titel-22.jpg">
    <source src={`${window.location.origin}/video/06_Jasmin_Web.mp4#t=0.001`} type="video/mp4" />
    Ihr Browser unterstützt kein Video.
  </video>
</TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG23-A",
    height: sectionDefaultHeight,
    image: image23,
    content: (
      <OuterTextContainer right>
        <TextContainer black>
          <Text bottompadding>
            Städtischer Friedhof Koppelweg, Trauerfeier mit Urnenbeisetzung: 
            Dekoration und Vorbereitung der Trauerfeier in der Friedhofshalle.
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG23",
    height: sectionDefaultHeight,
    image: image23,
    content: (
      <OuterTextContainer right>
        <TextContainer black>
          <Text tall bottompadding underlined>
            » Man ist nicht diejenige, die gerade jemanden verloren hat. Und das sollte auch nie irgendwie verwechselt werden. Man darf diese Trauer nicht mit nach Hause nehmen…«
          </Text>
          <Text bottompadding>
            <ReactAudioPlayer
              src={window.location.origin + "/audio/06_Jasmin_Audiozitat_Web.m4a"}
              style={{ outline: "none" }}
              controls
              controlsList="nodownload"
              preload="none"
            />
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG24",
    height: sectionDefaultHeight,
    image: image24,
    imagePosition: "left",
    content: (
      <OuterTextContainer right>
        <TextContainer>
          <Text bottompadding>
            Friedhof St. Simeon-St. Lukas, Stille Urnenbeisetzung mit vorherigem stillen Abschied in der Friedhofskapelle: 
            Die Aschekapsel wurde auf Wunsch der Angehörigen ohne Schmuckurne, nur im Versenknetz, ins Grab gesenkt.
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG25",
    height: sectionDefaultHeight,
    image: image25,
    imagePosition: "right",
    content: (
      <OuterTextContainer left>
        <TextContainer>
          <Text bottompadding>
            Warten vor der Tür der Abteilung Sterberegister des Standesamtes Neukölln.
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG26-A",
    height: sectionDefaultHeight,
    image: image26,
    content: (
      <OuterTextContainer left>
        <TextContainer white>
          <Text tall bottompadding underlined black>
            Tim Schamborski (*1992)
          </Text>
          <Text bottompadding black>
            Bestattermeister • arbeitet im Familienbetrieb Bestattungshaus Schamborski • Stubbendorf • Schleswig-Holstein
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG26",
    height: sectionDefaultHeight,
    image: image26,
    content: (
      <OuterTextContainer left>
<TextContainer>
  <video style={{maxHeight: "100vh", width:"100%"}} controls preload="none" poster="/video/video-player-titel-26.jpg">
    <source src={`${window.location.origin}/video/07_Tim_Web.mp4#t=0.001`} type="video/mp4" />
    Ihr Browser unterstützt kein Video.
  </video>
</TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG27-A",
    height: sectionDefaultHeight,
    image: image27,
    imagePosition: "right",
    content: (
      <OuterTextContainer left>
        <TextContainer black>
          <Text bottompadding>
            Tim auf dem Parkplatz vor dem Rathaus/Standesamt Kiel. Er hält einen Moment inne bevor er ins Amt geht.
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
    sectionId: "IMG27",
    height: sectionDefaultHeight,
    image: image27,
    imagePosition: "right",
    content: (
      <OuterTextContainer left>
        <TextContainer black>
          <Text tall bottompadding underlined>
            » Wir Bestatterinnen und Bestatter, wir sind die Bestattungskultur, wir prägen sie. Wir haben die Ideen und auch die Aufgaben, die die Familien uns stellen.«
          </Text>
          <Text bottompadding>
            <ReactAudioPlayer
              src={window.location.origin + "/audio/07_Tim_Audiozitat_Web.m4a"}
              style={{ outline: "none" }}
              controls
              controlsList="nodownload"
              preload="none"
            />
          </Text>
        </TextContainer>
      </OuterTextContainer>
    ),
  },
  {
      sectionId: "IMG28",
      height: sectionDefaultHeight,
      image: image28,
      imagePosition: "left",
      content: (
        <OuterTextContainer right>
          <TextContainer>
            <Text bottompadding>
              Urnenbeisetzung im Ruheforst Damp. Tim spricht die Trauerworte zu den Angehörigen.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG29",
      height: sectionDefaultHeight,
      image: image29,
      imagePosition: "right",
      content: (
        <OuterTextContainer left>
          <TextContainer>
            <Text bottompadding>
              Stille Seebestattung: Die Urne schwimmt noch für ein paar Minuten auf den Wellen,
              bevor Wasser eindringt und sie zum Grund sinkt. Nach 72 Std. hat sich die Seeurne komplett aufgelöst.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG30-A",
      height: sectionDefaultHeight,
      image: image30,
      content: (
        <OuterTextContainer right>
          <TextContainer white>
            <Text tall bottompadding underlined black>
              Kay Seemann (*1995)
            </Text>
            <Text bottompadding black>
              Bestattermeister • arbeitet im Familienbetrieb Beerdigungs-Institut Seemann & Söhne • Hamburg
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG30",
      height: sectionDefaultHeight,
      image: image30,
      content: (
        <OuterTextContainer right>
<TextContainer>
  <video style={{maxHeight: "100vh", width:"100%"}} controls preload="none" poster="/video/video-player-titel-30.jpg">
    <source src={`${window.location.origin}/video/08_Kay_Web.mp4#t=0.001`} type="video/mp4" />
    Ihr Browser unterstützt kein Video.
  </video>
</TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG31-A",
      height: sectionDefaultHeight,
      image: image31,
      mobileImagePosition: "right",
      content: (
        <OuterTextContainer left>
          <TextContainer black>
            <Text bottompadding>
              Besprechung mit einem Mitarbeiter im Stammhaus Blankenese, am Arbeitsplatz des Vaters.
              Weil dieser gerade Urlaub hat, übernimmt Kay so lange die Geschäftsführung.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG31",
      height: sectionDefaultHeight,
      image: image31,
      mobileImagePosition: "right",
      content: (
        <OuterTextContainer left>
          <TextContainer black>
            <Text tall bottompadding underlined>
              » … Ich habe den ganz großen Vor- bzw. Nachteil, dass ich einfach sehr viel älter aussehe, als ich bin.«
            </Text>
            <Text bottompadding>
              <ReactAudioPlayer
                src={window.location.origin + "/audio/08_Kay_Audiozitat_Web.m4a"}
                style={{ outline: "none" }}
                controls
                controlsList="nodownload"
                preload="none"
              />
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG32",
      height: sectionDefaultHeight,
      image: image32,
      imagePosition: "left",
      content: (
        <OuterTextContainer right>
          <TextContainer>
            <Text bottompadding>
            Einbalsamierung einer Verstorbenen zwecks Überführung mit dem Flugzeug nach Afrika. 
            Kay bei der thanatopraktischen Arbeit.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG33",
      height: sectionDefaultHeight,
      image: image33,
      
      content: (
        <OuterTextContainer left>
          <TextContainer>
            <Text bottompadding>
              Formulare ausfüllen. Im Kühlraum vom Trauerzentrum Schenefeld.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG34",
      height: sectionDefaultHeight,
      image: image34,
      imagePosition: "left",
      content: (
        <OuterTextContainer right>
          <TextContainer>
            <Text bottompadding>
              Bundesausbildungszentrum der Bestatter in Münnerstadt (Unterfranken): Hygienekurs des zweiten Ausbildungsjahres zur Bestattungsfachkraft. Kay vermittelt als Dozent die praktischen Grundlagen der Hygienischen Versorgung und ist auf dem Weg zum Hygieneraum.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG35-A",
      height: sectionDefaultHeight,
      image: image35,
      content: (
        <OuterTextContainer left>
          <TextContainer white>
            <Text tall bottompadding underlined black>
              Tim Osterndorff (*1992)
            </Text>
            <Text bottompadding black>
              Bestattermeister • arbeitet im Familienbetrieb Hadeler Bestattungen • Bremerhaven
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG35",
      height: sectionDefaultHeight,
      image: image35,
      content: (
        <OuterTextContainer left>
<TextContainer>
  <video style={{maxHeight: "100vh", width:"100%"}} controls preload="none" poster="/video/video-player-titel-35.jpg">
    <source src={`${window.location.origin}/video/09_Tim_Bremerhaven_Web.mp4#t=0.001`} type="video/mp4" />
    Ihr Browser unterstützt kein Video.
  </video>
</TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG36",
      height: sectionDefaultHeight,
      image: image36,
      mobileImagePosition: "left",
      content: (
        <OuterTextContainer left>
          <TextContainer>
            <Text bottompadding>
              Gitta Osterndorff, die Mutter von Tim, macht die Buchhaltung und beantwortet Anrufe im Betrieb, während Tim nach einem Dokument sucht.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG37-A",
      height: sectionDefaultHeight,
      image: image37,
      mobileImagePosition: "left",
      content: (
        <OuterTextContainer right>
          <TextContainer black>
            <Text bottompadding>
              Nachbesprechung einer Bestattungsvorsorge.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG37",
      height: sectionDefaultHeight,
      image: image37,
      mobileImagePosition: "left",
      content: (
        <OuterTextContainer right>
          <TextContainer black>
            <Text tall bottompadding underlined>
              » Was wir hier in unserem Beruf machen, ist nicht das Verkaufen und Vermarkten einer Dienstleistung, sondern Familien in Ausnahmesituation zu helfen…«
            </Text>
            <Text bottompadding>
              <ReactAudioPlayer
                src={window.location.origin + "/audio/09_Tim_Bremerhaven_Audiozitat_Web.m4a"}
                style={{ outline: "none" }}
                controls
                controlsList="nodownload"
                preload="none"
              />
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG38",
      height: sectionDefaultHeight,
      image: image38,
      
      content: (
        <OuterTextContainer left>
          <TextContainer>
            <Text bottompadding>
              Abholung eines Verstorbenen im AMEOS Klinikum Mitte. Tim und Paul im Vorflur der Prosektur auf dem Weg zum Auto.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG39-A",
      height: sectionDefaultHeight,
      image: image39,
      mobileImagePosition: "left",
      content: (
        <OuterTextContainer right>
          <TextContainer white>
            <Text tall bottompadding underlined black>
              Merrick Rohwer (*1997)
            </Text>
            <Text bottompadding black>
              Bestattungsfachkraft • angestellt beim Bestattungs-Institut Wellborg • Achim • Niedersachsen
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG39",
      height: sectionDefaultHeight,
      image: image39,
      mobileImagePosition: "left",
      content: (
        <OuterTextContainer right>
<TextContainer>
  <video style={{maxHeight: "100vh", width:"100%"}} controls preload="none" poster="/video/video-player-titel-39.jpg">
    <source src={`${window.location.origin}/video/10_Merrick_Web.mp4#t=0.001`} type="video/mp4" />
    Ihr Browser unterstützt kein Video.
  </video>
</TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG40-A",
      height: sectionDefaultHeight,
      image: image40,
      imagePosition: "left",
      content: (
        <OuterTextContainer right>
          <TextContainer black>
            <Text bottompadding>
              In der Umkleide der Mitarbeiter: Zum Dienstende zieht Merrick seine »zivile« Kleidung an.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG40",
      height: sectionDefaultHeight,
      image: image40,
      imagePosition: "left",
      content: (
        <OuterTextContainer right>
          <TextContainer black>
            <Text tall bottompadding underlined>
              » Ja, das stimmt definitiv, dass wir sehr schwarzen Humor haben… Wir verwenden den tatsächlich zum Verarbeiten der erlebten Dinge auf der Arbeit, damit wir damit klarkommen.«
            </Text>
            <Text bottompadding>
              <ReactAudioPlayer
                src={window.location.origin + "/audio/10_Merrick_Audiozitat_Web.m4a"}
                style={{ outline: "none" }}
                controls
                controlsList="nodownload"
                preload="none"
              />
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG41",
      height: sectionDefaultHeight,
      image: image41,
      
      content: (
        <OuterTextContainer left>
          <TextContainer>
            <Text bottompadding>
              Abholung eines Verstorbenen: Umlagern des Verstorbenen in die Kühlung des Bestattungsinstitutes. 
              Die Kollegin Lina (drittes Ausbildungsjahr zur Bestattungsfachkraft) hilft Merrick dabei.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG42",
      height: sectionDefaultHeight,
      image: image42,
      imagePosition: "left",
      content: (
        <OuterTextContainer right>
          <TextContainer>
            <Text bottompadding>
              Die Kerzen in der institutseigenen Trauerhalle werden ausgemacht.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG43-A",
      height: sectionDefaultHeight,
      image: image43,
      mobileImagePosition: "right",
      content: (
        <OuterTextContainer left>
          <TextContainer white>
            <Text tall bottompadding underlined black>
              Hans Dieckmann (*1997)
            </Text>
            <Text bottompadding black>
              Bestattungsfachkraft • arbeitet im Familienbetrieb Bestattungen Dieckmann • Brandenburg an der Havel • Brandenburg
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG43",
      height: sectionDefaultHeight,
      image: image43,
      mobileImagePosition: "right",
      content: (
        <OuterTextContainer left>
<TextContainer>
  <video style={{maxHeight: "100vh", width:"100%"}} controls preload="none" poster="/video/video-player-titel-43.jpg">
    <source src={`${window.location.origin}/video/11_Hans_Web.mp4#t=0.001`} type="video/mp4" />
    Ihr Browser unterstützt kein Video.
  </video>
</TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG44",
      height: sectionDefaultHeight,
      image: image44,
      imagePosition: "right",
      content: (
        <OuterTextContainer left>
          <TextContainer>
            <Text bottompadding>
              Altstädtischer Friedhof Brandenburg an der Havel: Urnenbeisetzung vom Auto aus. Hans mit dem Kollegen Eric und dem Auszubildenen Finley.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG45-A",
      height: sectionDefaultHeight,
      image: image45,
      mobileImagePosition: "right",
      content: (
        <OuterTextContainer left>
          <TextContainer black>
            <Text bottompadding>
              Feuerbestattungen Brandenburg: Ankunft an der Anlieferung des Krematoriums.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG45",
      mobileImagePosition: "right",
      height: sectionDefaultHeight,
      image: image45,
      content: (
        <OuterTextContainer left>
          <TextContainer black>
            <Text tall bottompadding underlined>
              » Ich selber bin kein Fan davon, dass wir in Social Media hochladen müssen, wie ich da stehe und einen Verstorbenen versorge. Mir persönlich ist es ein zu lockeres Umgehen mit dem Thema Tod.«
            </Text>
            <Text bottompadding>
              <ReactAudioPlayer
                src={window.location.origin + "/audio/11_Hans_Audiozitat_Web.m4a"}
                style={{ outline: "none" }}
                controls
                controlsList="nodownload"
                preload="none"
              />
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG46",
      height: sectionDefaultHeight,
      image: image46,
      imagePosition: "left",
      content: (
        <OuterTextContainer right>
          <TextContainer>
            <Text bottompadding>
              Feuerbestattungen Brandenburg: Ein Sarg fährt in den Ofen.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG47-A",
      height: sectionDefaultHeight,
      image: image47,
      content: (
        <OuterTextContainer left>
          <TextContainer white>
            <Text tall bottompadding underlined black>
              Antonia Gneist (*2004)
            </Text>
            <Text bottompadding black>
              Zweites Ausbildungsjahr zur Bestattungsfachkraft • angestellt bei AVALON Bestattungen • Halle an der Saale • Sachsen-Anhalt
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG47",
      height: sectionDefaultHeight,
      image: image47,
      content: (
        <OuterTextContainer left>
<TextContainer>
  <video style={{maxHeight: "100vh", width:"100%"}} controls preload="none" poster="/video/video-player-titel-47.jpg">
    <source src={`${window.location.origin}/video/12_Antonia_Web.mp4#t=0.001`} type="video/mp4" />
    Ihr Browser unterstützt kein Video.
  </video>
</TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG48",
      height: sectionDefaultHeight,
      image: image48,
      imagePosition: "right",
      content: (
        <OuterTextContainer left>
          <TextContainer>
            <Text bottompadding>
              Friedgarten Mitteldeutschland, Kabelsketal-Osmünde: 
              Vorbereitungen zur Urnenfeier mit anschließender Urnenbeisetzung in der Trauerhalle.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG49",
      height: sectionDefaultHeight,
      image: image49,
      mobileImagePosition: "left",
      content: (
        <OuterTextContainer right>
          <TextContainer>
            <Text bottompadding>
              Friedgarten Mitteldeutschland: Antonia wartet mit dem Blumenwagen auf das Ende der Urnenbeisetzung.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG50-A",
      height: sectionDefaultHeight,
      image: image50,
      imagePosition: "right",
      content: (
        <OuterTextContainer left>
          <TextContainer black>
            <Text bottompadding>
              Nach der stillen Erdbestattung laufen Antonia und Robert, ihr Ausbilder, zurück zum Auto.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG50",
      height: sectionDefaultHeight,
      image: image50,
      imagePosition: "right",
      content: (
        <OuterTextContainer left>
          <TextContainer black>
            <Text tall bottompadding underlined>
              » … alte weiße Männer. Es ist dieses typische Bild, was man im Kopf hat, wenn man hört: Bestatter!«
            </Text>
            <Text bottompadding>
              <ReactAudioPlayer
                src={window.location.origin + "/audio/12_Antonia_Audiozitat_Web.m4a"}
                style={{ outline: "none" }}
                controls
                controlsList="nodownload"
                preload="none"
              />
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG51-A",
      height: sectionDefaultHeight,
      image: image51,
      content: (
        <OuterTextContainer left>
          <TextContainer white>
            <Text tall bottompadding underlined black>
              Linda Heiland (*1989)
            </Text>
            <Text bottompadding black>
              Bestattermeisterin • arbeitet im Familienbetrieb Bestattungshaus Kruse • Usedom • Mecklenburg-Vorpommern
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG51",
      height: sectionDefaultHeight,
      image: image51,
      content: (
        <OuterTextContainer left>
<TextContainer>
  <video style={{maxHeight: "100vh", width:"100%"}} controls preload="none" poster="/video/video-player-titel-51.jpg">
    <source src={`${window.location.origin}/video/13_Linda_Web.mp4#t=0.001`} type="video/mp4" />
    Ihr Browser unterstützt kein Video.
  </video>
</TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG52",
      height: sectionDefaultHeight,
      image: image52,
      
      content: (
        <OuterTextContainer left>
          <TextContainer>
            <Text bottompadding>
              Sargausstellung: Linda beim Abbau eines künstlichen Baumes, den sie für eine anstehende Trauerfeier benötigt.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG53",
      height: sectionDefaultHeight,
      image: image53,
      mobileImagePosition: "right",
      content: (
        <OuterTextContainer left>
          <TextContainer>
            <Text bottompadding>
              Auf dem Friedhof Neuhof findet eine Trauerfeier mit anschließender Urnenbeisetzung statt. Die Gräber werden per Hand ausgehoben, da Urnenbohrer und Minibagger aufgrund der sandigen Bodenbeschaffenheit von Usedom oft nicht funktionieren. Dietwin Genz, der technische Mitarbeiter, wechselt sich jedes Quartal mit seinem Kollegen ab, um diese Aufgabe zu erledigen. Die Satzungen und Anordnungen der Friedhofsträger erlauben oft nur »Handaushebungen«.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG54-A",
      height: sectionDefaultHeight,
      image: image54,
      mobileImagePosition: "left",
      content: (
        <OuterTextContainer right>
          <TextContainer black>
            <Text bottompadding>
              Friedhof Neuhof, Trauerfeier mit anschließender Urnenbeisetzung: Linda hält die Trauerrede.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG54",
      height: sectionDefaultHeight,
      image: image54,
      mobileImagePosition: "left",
      content: (
        <OuterTextContainer right>
          <TextContainer black>
            <Text tall bottompadding underlined>
              » Ich muss sagen, dass ich privat das größte emotionale Wrack bin, da fange ich bei Disney-Filmen an zu weinen. Aber auf der Arbeit ist das anders.«
            </Text>
            <Text bottompadding>
              <ReactAudioPlayer
                src={window.location.origin + "/audio/13_Linda_Audiozitat_Web.m4a"}
                style={{ outline: "none" }}
                controls
                controlsList="nodownload"
                preload="none"
              />
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG55-A",
      height: sectionDefaultHeight,
      image: image55,
      mobileImagePosition: "right",
      content: (
        <OuterTextContainer left>
          <TextContainer white>
            <Text tall bottompadding underlined black>
              Esther Fuß (*2001)
            </Text>
            <Text bottompadding black>
              Bestattungsfachkraft • angestellt beim Bestattungshaus Poths • Limburg • Hessen
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG55",
      height: sectionDefaultHeight,
      image: image55,
      mobileImagePosition: "right",
      content: (
        <OuterTextContainer left>
<TextContainer>
  <video style={{maxHeight: "100vh", width:"100%"}} controls preload="none" poster="/video/video-player-titel-55.jpg">
    <source src={`${window.location.origin}/video/14_Esther_Web.mp4#t=0.001`} type="video/mp4" />
    Ihr Browser unterstützt kein Video.
  </video>
</TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG56",
      height: sectionDefaultHeight,
      image: image56,
      mobileImagePosition: "left",
      content: (
        <OuterTextContainer left>
          <TextContainer>
            <Text bottompadding>
              Aufbewahrungsgläser im Nebenraum des Büros.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG57-A",
      height: sectionDefaultHeight,
      image: image57,
      imagePosition: "right",
      content: (
        <OuterTextContainer left>
          <TextContainer black>
            <Text bottompadding>
              Friedhof Elz: Trauerfeier mit anschließender Urnenbeisetzung. Esther beim Einpacken der Deko-Säulen nach der Trauerfeier.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG57",
      height: sectionDefaultHeight,
      image: image57,
      imagePosition: "right",
      content: (
        <OuterTextContainer left>
          <TextContainer black>
            <Text tall bottompadding underlined>
              » Digitalisierung kann keine Empathie. Und das ist das Wichtigste in unserem Job.«
            </Text>
            <Text bottompadding>
              <ReactAudioPlayer
                src={window.location.origin + "/audio/14_Esther_Audiozitat_Web.m4a"}
                style={{ outline: "none" }}
                controls
                controlsList="nodownload"
                preload="none"
              />
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG58",
      height: sectionDefaultHeight,
      image: image58,
      mobileImagePosition: "left",
      content: (
        <OuterTextContainer right>
          <TextContainer>
            <Text bottompadding>
              Friedhof Hofen, Runkel-Hofen: Esther und Bianca Bänsch-Poths bringen den Blumenschmuck zum Grab, das bereits vom Friedhofsgärtner geschlossen wird.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG59-A",
      height: sectionDefaultHeight,
      image: image59,
      content: (
        <OuterTextContainer right>
          <TextContainer white>
            <Text tall bottompadding underlined black>
              Franziska Ritscher (*1995)
            </Text>
            <Text bottompadding black>
              Zweites Ausbildungsjahr zur Bestattungsfachkraft • angestellt beim Bestattungsinstitut Gotha • Gotha • Thüringen
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG59",
      height: sectionDefaultHeight,
      image: image59,
      content: (
        <OuterTextContainer right>
<TextContainer>
  <video style={{maxHeight: "100vh", width:"100%"}} controls preload="none" poster="/video/video-player-titel-59.jpg">
    <source src={`${window.location.origin}/video/15_Franziska_Web.mp4#t=0.001`} type="video/mp4" />
    Ihr Browser unterstützt kein Video.
  </video>
</TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG60-A",
      height: sectionDefaultHeight,
      image: image60,
      imagePosition: "right",
      mobileImagePosition: "left",
      content: (
        <OuterTextContainer left>
          <TextContainer black>
            <Text bottompadding>
              Franziska nimmt den kürzeren Weg zu den Pausenräumen. Sie klettert über die Friedhofsmauer.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG60",
      height: sectionDefaultHeight,
      image: image60,
      imagePosition: "right",
      mobileImagePosition: "left",
      content: (
        <OuterTextContainer left>
          <TextContainer black>
            <Text tall bottompadding underlined>
              » … dass es nicht nur die schöne heile TikTok-Welt ist, die diesen Beruf ausmacht. Dass man nachts auch mal über Bahngleise kriechen muss, um einen Verstorbenen einzusammeln.«
            </Text>
            <Text bottompadding>
              <ReactAudioPlayer
                src={window.location.origin + "/audio/15_Franziska_Audiozitat_Web.m4a"}
                style={{ outline: "none" }}
                controls
                controlsList="nodownload"
                preload="none"
              />
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG61",
      height: sectionDefaultHeight,
      image: image61,
      
      content: (
        <OuterTextContainer left>
          <TextContainer>
            <Text bottompadding>
              Prüfungsvorbereitung in der Sargwerkstatt: Der Ausbilder Ronny zeigt Franziska, wie man einen Sarg ausschlägt.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG62",
      height: sectionDefaultHeight,
      image: image62,
      imagePosition: "left",
      content: (
        <OuterTextContainer right>
          <TextContainer>
            <Text bottompadding>
              Urnenausstellung im Bestattungsinstitut: In den Gedenklichtern oder »Miniurnen« können Erinnerungsgegenstände wie Haare der Verstorbenen aufbewahrt werden.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG63-A",
      height: sectionDefaultHeight,
      image: image63,
      content: (
        <OuterTextContainer left>
          <TextContainer white>
            <Text tall bottompadding underlined black>
              Zoe Duchêne (*2001)
            </Text>
            <Text bottompadding black>
              Bestattungsfachkraft • angestellt beim Bestattungshaus "Friede" DUCHENE • Völklingen • Saarland
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG63",
      height: sectionDefaultHeight,
      image: image63,
      content: (
        <OuterTextContainer left>
<TextContainer>
  <video style={{maxHeight: "100vh", width:"100%"}} controls preload="none" poster="/video/video-player-titel-63.jpg">
    <source src={`${window.location.origin}/video/16_Zoe_Web.mp4#t=0.001`} type="video/mp4" />
    Ihr Browser unterstützt kein Video.
  </video>
</TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG64",
      height: sectionDefaultHeight,
      image: image64,
      imagePosition: "left",
      content: (
        <OuterTextContainer right>
          <TextContainer>
            <Text bottompadding>
              Zoe bereitet die anstehende Trauerfeier in der Friedhofshalle vor und dokumentiert die Dekoration.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG65",
      height: sectionDefaultHeight,
      image: image65,
      mobileImagePosition: "right",
      content: (
        <OuterTextContainer left>
          <TextContainer>
            <Text bottompadding>
              Im Hof des Bestattungshauses gibt Zoe die Einweisung für die anschließende Beisetzung an die Bestattungshelfer.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG66-A",
      height: sectionDefaultHeight,
      image: image66,
      imagePosition: "right",
      content: (
        <OuterTextContainer left>
          <TextContainer black>
            <Text bottompadding>
              Keller des Bestattungshauses. Hier beschriftet Zoe ein Grabkreuz für die anstehende Trauerfeier.
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
    {
      sectionId: "IMG66",
      height: sectionDefaultHeight,
      image: image66,
      imagePosition: "right",
      content: (
        <OuterTextContainer left>
          <TextContainer black>
            <Text tall bottompadding underlined>
              » Ich kann mir nichts anderes mehr vorstellen. Ich habe den schönsten Beruf der Welt.«
            </Text>
            <Text bottompadding>
              <ReactAudioPlayer
                src={window.location.origin + "/audio/16_Zoe_Audiozitat_Web.m4a"}
                style={{ outline: "none" }}
                controls
                controlsList="nodownload"
                preload="none"
              />
            </Text>
          </TextContainer>
        </OuterTextContainer>
      ),
    },
];
