import React from "react";
import styled, {css} from "styled-components";
import {
  TextOnlyContainer,
  Text, OuterTextContainer,
} from "../components/styledcomponents";
import ReactAudioPlayer from "react-audio-player";
import Portrait from "../img-mm40/01_About_Teichmann_mm40.jpg";
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';


const ImprintContainer = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-Y: scroll;
  background-color: #fff !important;
  color: #333 !important;
  paddingTop: 100;
  paddingBottom: 100;
`;

export class About extends React.Component {

  render() {
    return (
      <ImprintContainer>
        <OuterTextContainer>
          <TextOnlyContainer>
            <div style={{wordWrap: "break-word"}}>
            <Text black xl underlined>Der Fotograf</Text>
            <Text black bottompadding>
              <img src={Portrait} style={{maxWidth: window.innerWidth < 450 ? "100%" : 450 }} />
              <br/><br/><b>Andreas Teichmann</b> (*1970) lebt mit seiner Familie in Essen. Er hat an der Folkwang Universität der Künste studiert und ist seit 1994 als selbstständiger Fotograf tätig. Mit Leidenschaft und Nachhaltigkeit arbeitet er an Projekten, die den Menschen und seine Umwelt untersuchen.
              <br/><br/>Er hat über die letzten drei Jahrzehnte zahlreiche Publikationen und Auszeichnungen im In- und Ausland bekommen. Darunter die Teilnahme an der World Press Masterclass und dem Hasselblad Master. Er ist Mitglied der Agentur laif Photos & Reportagen, freelens und der DGPh.
              <br/><br/><a href={"http://www.andreasteichmann.de/"} target={"_blank"}>www.andreasteichmann.de</a>
              <br/><a href={"https://www.instagram.com/andreas.teichmann/"} target={"_blank"}>Instagram: andreas.teichmann</a>
              <br/><br/>Das Projekt wurde unterstützt und gefördert durch die Stiftung Deutsche Bestattungskultur.
              <br/><div style={{width: 500, maxWidth: "85vw"}}><img src="Logo-Stiftung-Deutsche-Bestattungskultur.jpeg" width={"100%"} /></div>
            </Text>
            
            <Text black xl underlined>Das Buch</Text>
            <Text black bottompadding>
            "Memento Mori 4.0: Die neue Generation der Bestatter*innen" ist eine authentische Dokumentation, die tief in die Seele eines Berufsstandes eintaucht, der sich aktuell in einem existentiellen Umbruch befindet.
            <br/><br/>Den moralischen und ethischen Grundwerten des Berufes verpflichtet, sucht eine junge Generation nach neuen und zeitgemäßen Symbolen und Ritualen, um Trauer und Schmerz, Trost und Empathie Ausdruck zu verleihen.
            <br/><br/>Dem Fotografen Andreas Teichmann ist es über einen Zeitraum von mehr als drei Jahren gelungen, Zugang zu einer Branche zu bekommen, der bisher nur wenigen gewährt wurde.
            <br/><br/>Seine Aufnahmen zeigen die Arbeitswirklichkeit junger Bestatter*innen abseits vermeintlicher Klischees und falscher Vorstellungen.
            <br/><br/>Zahlreiche Begegnung liefern faszinierende Einblicke in eine Welt zwischen Tradition und Innovation, zwischen Leben und Tod.
            <br/><br/>Was bewegt einen jungen Menschen, Bestatter*in zu werden? Und wie verändert sich das eigene Leben, wenn man sich immer wieder mit dem Tod beschäftigt?
            <br/><br/>Anhand von 16 jungen Persönlichkeiten dokumentiert Teichmann seine fotografische Recherche, die ihn in alle Regionen des Landes geführt hat.
            <br/><br/>Begleitet von einfühlsamen Gesprächen, offenbaren seine Aufnahmen unerwartete Begebenheiten und Einsichten in den Arbeitsalltag und die Gedankenwelt der Porträtierten.
            <br/><br/>Das Ergebnis ist eine bislang unbekannte und faszinierende Reise durch die deutsche Bestattungskultur der Gegenwart.
            <br/><br/>Das Buch zum kompletten Projekt ist im Verlag Kettler erschienen und kann hier bestellt werden:
            <br/><br/><a href="https://www.verlag-kettler.de/de/buecher/memento-mori-40/" target="_blank">www.verlag-kettler.de/de/buecher/memento-mori-40</a>
             </Text>
             
             <Text black xl underlined>Ausstellung</Text>
             <Text black bottompadding>
              <a href="https://www.sepulkralmuseum.de/veranstaltungen/veranstaltungen/memento-mori-4.0" target="_blank">www.sepulkralmuseum.de/veranstaltungen/veranstaltungen/memento-mori-4.0</a>
             </Text>
             
             <Text black xl underlined>Presse</Text>
             <Text black bottompadding>
              folgt ...
              </Text>
              </div>
          </TextOnlyContainer>
        </OuterTextContainer>
      </ImprintContainer>
    );
  }
}